import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ZaragozaPage from "./components/zaragoza/ZaragozaPage";
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MainContent from './routes/MainContent';

const App = () => {
    return (
        <Router>
            <Header />

            <Routes>
                {/* Main Single Page */}
                <Route path="/" element={<MainContent />} />

                {/* Hidden Zaragoza Page */}
                <Route path="/zaragoza" element={<ZaragozaPage />} />
            </Routes>

            <Footer />
        </Router>
    );
};

export default App;
