import React from 'react';
import './ZaragozaPage.css';
import Downloads from '../downloads/Downloads';

const ZaragozaPage = () => {
    const today = new Date().toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    });

    return (
        <div className="zaragoza-page container">
            <h1 className='zaragoza__title'>Zaragoza Workshop in Time Series Econometrics</h1>
            <h3 className='zaragoza__subtitle'>{today}</h3>
            <Downloads />
        </div>
    );
};

export default ZaragozaPage;
