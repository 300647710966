import React from 'react';
import './downloads.css'; // Ensure this CSS file includes the necessary styles
import paper1 from '../../assets/QuantilesWLatentComponents.pdf';
import paper2 from '../../assets/Quantiles_5min.pdf';

const Downloads = () => {
    const downloads = [
        { 
            type: "Paper",
            title: "Measuring and Forecasting Quantiles with Latent Components", 
            subtitle: "Preliminary, please do not circulate", 
            year: 2025, 
            file: paper1,
        },
        {   type: "Slides",
            title: "Measuring and Forecasting Quantiles with Latent Components", 
            subtitle: "Workshop in Time Series Econometrics", 
            year: 2025, 
            file: paper2,
        }
    ];

    return (
        <section className="downloads section" id="downloads">
            <div className="downloads__container container grid">
                <ul className="downloads__list">
                    {downloads.map((download, index) => (
                        <li key={index} className="downloads__item">
                            <a href={download.file} download className="downloads__link">
                                <div>
                                    <h3 className="downloads__title">{download.title}</h3>
                                    <h4 className="downloads__type">{download.type}</h4>
                                    <p className="downloads__subtitle">{download.subtitle}</p>
                                    <span className="downloads__year">{download.year}</span>
                                </div>
                            </a>

                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Downloads;
