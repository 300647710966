import React from "react";
import Home from "../components/home/home";
import Research from "../components/research/Research";
import Resume from "../components/resume/Resume";

const MainContent = () => {
  return (
    <main className="main">
      <Home />
      <Research />
      <Resume />
    </main>
  );
};

export default MainContent;
